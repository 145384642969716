import Cookie from "js-cookie";
import { RegistService } from "@/services";
import fftData from "@/views/regist/util";
import { UploadUrl } from "@/services/config";
import Contract from "@/components/sign/Contract.vue";
import { pca, pcaa } from 'area-data'; // v5 or higher
import PubSub from "@/lib/Pub";

export default {
	name: "SignMechanism",
	components: {
		Contract,
	},
	data() {
		return {
			pca,
			pcaa,
			showContract: false,
			showLoading: false,
			httpHeader: {
				token: Cookie.get("token")
			},
			UploadUrl,
			formData: {
				name: '',
				area: [],
				detailArea: null,
				establishTime: "",
				clinicalCount: null,
				crcSoure: [],
				crcCount: null,
				acceptCrcCount: null,
				advancedProject: [],
				licenseImg: "",
				linkName: this.$root.userInfo.name || '',
				linkPhone: "",
				linkEmail: this.$root.userInfo.email || '',
				linkAddress: '',
				position: '',
				linkZcode: '',
				linkFax: ''
			},
			rules: {
				name: [{ required: true, message: "必填", trigger: "blur" }],
				area: [{ required: true, message: "必填", trigger: "blur" }],
				detailArea: [{ required: true, message: "必填", trigger: "blur" }],
				establishTime: [{ required: true, message: "必选", trigger: "blur" }],
				clinicalCount: [
					{ required: true, message: "必填", trigger: "blur" },
					{
						type: "number",
						message: "必须为数字值",
						trigger: ["blur", "change"]
					}
				],
				crcSoure: [
					{
						required: true,
						message: "至少选择一个",
						trigger: ["blur", "change"]
					}
				],
				crcCount: [
					{ required: true, message: "必填", trigger: "blur" },
					{
						type: "number",
						message: "必须为数字值",
						trigger: ["blur", "change"]
					}
				],
				acceptCrcCount: [
					{ required: true, message: "必填", trigger: "blur" },
					{
						type: "number",
						message: "必须为数字值",
						trigger: ["blur", "change"]
					}
				],
				advancedProject: [
					{ required: true, message: "必填", trigger: ["blur", "change"] }
				],
				licenseImg: [
					{
						required: true,
						message: "请上传临时试验机构申请表",
						trigger: "blur"
					}
				],
				linkName: [{ required: true, message: "必填", trigger: "blur" }],
				linkPhone: [
					{ required: true, message: "必填", trigger: "blur" },
					{ pattern: /^1[34578]\d{9}$/, message: '输入正确的手机号格式' }
				],
				linkEmail: [
					{ required: true, message: "必填", trigger: "blur" },
					{
						type: "email",
						message: "请输入正确的邮箱地址",
						trigger: "blur"
					}
				],
				linkAddress: [{ required: true, message: "必填", trigger: "blur" }],
				gUserId: [{ required: true, message: "必填", trigger: "blur" }],
				position: "",
				linkZcode: [{ pattern: /\d{6}$/, message: '请输入正确的邮编' }],
				linkFax: ""
			}
		};
	},
	async created() {
		this.showLoading = true;
		let res = await RegistService.getSignInfo(1);
		this.showLoading = false;
		if (res.code === 200) {
			this.formData = fftData(res.data, this.formData);
		} else if (res.code === 401) {
			PubSub.publish("showLoginEvent");
			return
		} else {
			this.$message("服务器异常，请稍后重试！");
		}
	},
	methods: {
		uploadSuccess(res) {
			if (res.code === 200) {
				let imgPath = res.data.name;
				this.formData.licenseImg = imgPath;
			} else if (res.code === 401) {
				this.$refs["uploader"].clearFiles();
				PubSub.publish("showLoginEvent");
			} else {
				this.$message(res.msg);
			}
		},
		onImageRemove() {
			this.formData.licenseImg = "";
		},
		back() {
			this.$router.back();
		},
		print() {
			window.print();
		},
		nextStep(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.showContract = true;
				} else {
					return false;
				}
			});
		},
		async submit() {
			let {
				name,
				area,
				detailArea,
				establishTime,
				clinicalCount,
				crcSoure,
				crcCount,
				acceptCrcCount,
				advancedProject,
				licenseImg,
				linkName,
				linkPhone,
				linkEmail,
				linkAddress,
				position,
				linkZcode,
				linkFax
			} = this.formData;
			crcSoure = crcSoure.join(",");
			advancedProject = advancedProject.join(",");
			let address = area.join(' ') + '/' + detailArea.replace(/\s/g, '');

			let data = {
				name,
				address,
				establishTime,
				clinicalCount,
				crcSoure,
				crcCount,
				acceptCrcCount,
				advancedProject,
				licenseImg,
				linkName,
				linkPhone,
				linkEmail,
				linkAddress,
				position,
				linkZcode,
				linkFax
			};
			let res = await RegistService.signMechansim(data);
			if (res.code === 200) {
				this.showContract = false;
				this.$msgbox({
					title: '提交成功',
					message: '恭喜您，注册成功！平台将会在10个工作日内，将审核结果发送到联系人邮箱，请注意查收！',
					callback: () => {
						this.$router.push("/");
					},
					showConfirmButton: false,
					type: 'success',
				});
			} else if (data.code === 401) {
				PubSub.publish("showLoginEvent");
			} else {
				this.$message("服务器异常，请稍后重试");
			}
		},
		downloadFile() {
			let {
				name,
				area,
				detailArea,
				establishTime,
				clinicalCount,
				crcSoure,
				crcCount,
				acceptCrcCount,
				advancedProject,
				linkName,
				linkPhone,
				linkEmail,
				linkAddress,
				position,
				linkZcode,
				linkFax
			} = this.formData;
			if (!detailArea) {
				this.$message("请填写信息后下载！");
				return
			}
			let address = area.join(' ') + '/' + detailArea.replace(/\s/g, '');
			crcSoure = crcSoure.join(",");
			advancedProject = advancedProject.join(",");

			let data = {
				name,
				address,
				establishTime: establishTime,
				clinicalCount,
				crcSoure: crcSoure,
				crcCount,
				acceptCrcCount,
				advancedProject: advancedProject,
				linkName,
				linkPhone,
				linkEmail,
				linkAddress,
				position,
				linkZcode,
				linkFax
			};
			RegistService.downloadFile(1, data);
		},
	}
};
